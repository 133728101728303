import React from "react";

export default React.forwardRef(function Blocks(props, ref) {
  return (
    <div class="fire-story-container4 container-fluid" ref={ref}>
      <div className="row">
        <div className="fire-story-container4-col1 col-12 col-md-4">
          Block 1
        </div>
        <div className="fire-story-container4-col2 col-12 col-md-4">
          Block 2
        </div>
        <div className="fire-story-container4-col3 col-12 col-md-4">
          Block 3
        </div>
      </div>
    </div>
  );
});
