import React from "react";

export default function Video() {
  return (
    <div className="fire-story-container2 container-fluid">
      <div className="row">
        <div className="video-container embed-responsive embed-responsive-16by9 col-12 col-lg-8">
          {/* Short video for testing use only */}
          <iframe
            title="test video"
            id="sc-vimeo"
            src="https://player.vimeo.com/video/205999456"
            width="640"
            height="512"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          ></iframe>
        </div>
        <div className="text col-12 col-lg-4">
          <h2>We spoke with #X people who lived through the fire:</h2>
          <p>
            community members, community leaders, and fire fighters. This
            webpage presents their stories so that we can remember the
            devastation caused by the fire, celebrate and learn from individual
            and community resilience in the face of disaster, and support
            continued efforts to restore and rejuvenate Talent and Phoenix.
          </p>
        </div>
      </div>
    </div>
  );
}
