import { useRef } from "react";

export default function Navbar({ blocks, sidebar, setSidebarTab }) {
  const navbarButtons = useRef(null);

  const handleClick = (tab) => {
    setSidebarTab({ displayed: true, tab: tab });

    // get bottom position of navbar buttons
    const buttonsBottom = navbarButtons.current.getBoundingClientRect().bottom;

    // get top position of blocks
    const blocksTop = blocks.current.getBoundingClientRect().top;

    const height = blocksTop - buttonsBottom;

    sidebar.current.style.height = height + "px";
    sidebar.current.classList.add("active");
    sidebar.current.firstChild.classList.add("active");
  };

  return (
    <nav class="fire-story-nav navbar navbar-expand-lg">
      <a class="navbar-brand" href="./">
        Logo
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto" ref={navbarButtons}>
          <li class="nav-item">
            <button
              class="fire-story-nav-button"
              onClick={() => {
                handleClick("learn");
              }}
            >
              Learn
            </button>
          </li>
          <li class="nav-item">
            <button
              class="fire-story-nav-button"
              onClick={() => {
                handleClick("share");
              }}
            >
              Share
            </button>
          </li>
          <li class="nav-item">
            <button
              class="fire-story-nav-button"
              onClick={() => {
                handleClick("donate");
              }}
            >
              Donate
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
}
