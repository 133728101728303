import React from "react";

export default React.forwardRef(function Sidebar({ tab }, ref) {
  return (
    <aside className="fire-story-sidebar" ref={ref}>
      <div className="fire-story-sidebar-content">
        <h1>{tab}</h1>
      </div>
    </aside>
  );
});
