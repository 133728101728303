// 3rd party imports
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useState, useEffect, useRef } from "react";

// Components
import Navbar from "./components/Navbar/Navbar";
import Video from "./components/Video/Video";
import Map from "./components/Map/Map";
import Blocks from "./components/Blocks/Blocks";
import Footer from "./components/Footer/Footer";
import Sidebar from "./components/Sidebar/Sidebar";

// local imports
import "./App.css";

function App() {
  const [sidebarTab, setSidebarTab] = useState({
    displayed: false,
    tab: "donate",
  });
  const sidebar = useRef(null);
  const quoteBlocks = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [sidebarTab]);

  const closeSidebar = () => {
    sidebar.current.classList.remove("active");

    setTimeout(() => {
      sidebar.current.firstChild.classList.remove("active");
      sidebar.current.style.height = null;
    }, 250);
    setSidebarTab({ ...sidebarTab, displayed: false });
  };

  const handleClickOutside = (e) => {
    if (sidebarTab.displayed && e.target !== sidebar.current) {
      closeSidebar();
    }
  };

  return (
    <>
      <Navbar
        blocks={quoteBlocks}
        sidebar={sidebar}
        sidebarTab={sidebarTab}
        setSidebarTab={setSidebarTab}
      ></Navbar>

      <main>
        <div className="fire-story-container1 container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>The Almeda Fire and its Aftermath:</h1>
              <p>Community Challenges and Resilience</p>
            </div>
          </div>
        </div>
        <Video></Video>
        <Map></Map>
        <Blocks ref={quoteBlocks}></Blocks>
      </main>

      <Footer></Footer>
      <Sidebar tab={sidebarTab.tab} ref={sidebar}></Sidebar>
    </>
  );
}

export default App;
